export const menuJs = [
  {
    name: "About MBA",
    children: [
      {
        name: "Why MBA",
        path: "/why-mba",
      },
      {
        name: "MBA vs PGDM",
        path: "/mba-or-pgdm",
      },
      {
        name: "Admission Process",
        children: [
          {
            name: "Admission Process",
            path: "/admission-process",
          },
          {
            name: "IIM Admission",
            path: "/admission-process",
          },
          {
            name: "IIM Admission Criteria",
            children: [
              {
                name: "IIM Ahmedabad",
                path: "/admission-process",
              },
              {
                name: "IIM Bangalore",
                path: "/cms?page=admission-process",
              },
              {
                name: "IIM - CALCUTTA",
                path: "/cms?page=admission-process",
              },
              {
                name: "IIM Lucknow",
                path: "/cms?page=admission-process",
              },
              {
                name: "IIM Indore",
                path: "/cms?page=admission-process",
              },
              {
                name: "IIM Kozhikode",
                path: "/cms?page=admission-process",
              },
              {
                name: "IIM Kashipur",
                path: "/cms?page=admission-process",
              },
              {
                name: "IIM Raipur",
                path: "/cms?page=admission-process",
              },
              {
                name: "All IIMs",
                path: "/cms?page=admission-process",
              },
            ],
          },
          {
            name: "New IIMS Process - CAP",
            path: "/admission-process",
          },
        ],
      },
      {
        name: "MBA Specialization",
        path: "/mba-more-specialisations",
        children: [
          {
            name: "MBA in Marketing",
            path: "/mba-in-marketing",
          },
          {
            name: "MBA in Finance",
            path: "/mba-in-finance",
          },
          {
            name: "MBA in HR",
            path: "/mba-in-hr",
          },
          {
            name: "MBA in Operations",
            path: "/mba-in-operations",
          },
          {
            name: "MBA in International Business",
            path: "/mba-in-ib",
          },
          {
            name: "MBA in Business Analytics",
            path: "/mba-in-ba",
          },
          {
            name: "More Specialization",
            path: "/cms?page=mba-more-specialisations",
          },
        ],
      },
      {
        name: "Types of MBA",
        children: [
          {
            name: "Online MBA",
            path: "/online-mba",
          },
          {
            name: "Executive MBA",
            path: "/executive-mba",
          },
          {
            name: "Distance MBA",
            path: "/distance-mba",
          },
          {
            name: "Integrated MBA",
            path: "/integrated-mba",
          },
          {
            name: "Part Time MBA",
            path: "/part-time-mba",
          },
        ],
      },
    ],
  },
  {
    name: "Colleges",
    children: [
      {
        name: "Top MBA Colleges",
        children: [
          {
            name: "NIRF MBA College Ranking",
            path: "/college-list?m=NIRF MBA College Ranking",
          },
          {
            name: "Top Government MBA Colleges",
            path: "/college-list?m=Top Government MBA Colleges",
          },
          {
            name: "Top Private MBA Colleges",
            path: "/college-list?m=Top Private MBA Colleges",
          },
        ],
      },
      {
        name: "Top Colleges in States",
        path: "#",
        children: [
          {
            name: "Top Colleges in Maharashtra",
            path: "/college-list?m=Top+Colleges+in+Maharashtra",
          },
          {
            name: "Top Colleges in Delhi",
            path: "/college-list?m=Top Colleges in Delhi",
          },
          {
            name: "Top Colleges in UP",
            path: "/college-list?m=Top Colleges in UP",
          },
          {
            name: "Top Colleges in Karnataka",
            path: "/college-list?m=Top Colleges in Karnataka",
          },
          {
            name: "Top Colleges in Haryana",
            path: "/college-list?m=Top Colleges in Haryana",
          },
          {
            name: "Top Colleges in West Bengal",
            path: "/college-list?m=Top Colleges in West Bengal",
          },
          {
            name: "All Locations",
            path: "/college-list?m=All Locations",
          },
        ],
      },
      {
        name: "Top Colleges in Cities",
        path: "/cms",
        children: [
          {
            name: "Top Colleges in Delhi/NCR",
            path: "/college-list?m=Top Colleges in Delhi/NCR",
          },
          {
            name: "Top Colleges in Pune",
            path: "/college-list?m=Top Colleges in Pune",
          },
          {
            name: "Top Colleges in Mumbai",
            path: "/college-list?m=Top Colleges in Mumbai",
          },
          {
            name: "Top Colleges in Bangalore",
            path: "/college-list?m=Top Colleges in Bangalore",
          },
          {
            name: "Top Colleges in Kolkata",
            path: "/college-list?m=Top Colleges in Kolkata",
          },
          {
            name: "Top Colleges in Chandigarh",
            path: "/college-list?m=Top Colleges in Chandigarh",
          },
          {
            name: "Top Colleges in Dehradun",
            path: "/college-list?m=Top Colleges in Dehradun",
          },
          {
            name: "Top Colleges in All Cities",
            path: "/college-list?m=Top Colleges in All Cities",
          },
        ],
      },
      {
        name: "Compare Colleges",
        path: "/college-list?q=",
      },
      {
        name: "Featured Colleges",
        path: "/part-time-mba",
      },
    ],
  },
  {
    name: "MBA Specialization",
    children: [
      {
        name: "MBA in Marketing Colleges",
        path: "/mba-in-marketing",
      },
      {
        name: "MBA in Finance Colleges",
        path: "/mba-in-finance",
      },
      {
        name: "MBA in HR Colleges",
        path: "/mba-in-hr",
      },
      {
        name: "MBA in Rural Management Colleges",
        path: "/mba-in-rm",
      },
      {
        name: "MBA in Operations Colleges",
        path: "/cms?page=mba-in-operations",
      },
      {
        name: "MBA in Healthcare Colleges",
        path: "/mba-in-hc",
      },
      {
        name: "MBA in International Business Colleges",
        path: "/mba-in-ib",
      },
      {
        name: "MBA in Business Analytics Colleges",
        path: "/mba-in-ba",
      },
      {
        name: "MBA in Banking Colleges",
        path: "/mba-in-bf",
      },
      {
        name: "More Specialization",
        path: "/cms?page=mba-more-specialisations",
      },
    ],
  },
  {
    name: "MBA Exams",
    children: [
      {
        name: "CAT 2022",
        path: "/cat-2022",
      },
      {
        name: "XAT 2023",
        path: "/xat-2023",
      },
      {
        name: "MAT",
        path: "/mat",
      },
      {
        name: "SNAP 2022",
        path: "/snap-2022",
      },
      {
        name: "ATMA",
        path: "/atma",
      },
      {
        name: "NMAT 2022",
        path: "/nmat-2022",
      },
      {
        name: "CMAT 2023",
        path: "/cmat-2023",
      },
      {
        name: "IBSAT",
        path: "/ibsat",
      },
      {
        name: "MAH CET",
        path: "/mah-cet",
      },
      {
        name: "All MBA Exams",
        path: "/cms?page=all-mba-exams",
      },
      {
        name: "Exam Calander",
        path: "/cms",
      },
    ],
  },
  {
    name: "More Courses",
    path: "/cms?page=more-courses",
    children: [
      {
        name: "Management",
        path: "",
        children: [
          {
            name: "BBA",
            path: "/cms?page=all-mba-exams",
            children: [
              {
                name: "Colleges by State",
                path: "/cms?page=mah-cet",
                children: [
                  {
                    name: "Top Colleges in maharashtra",
                    path: "/cms?page=colleges-in-mh",
                  },
                  {
                    name: "Top Colleges in Delhi",
                    path: "/cms?page=colleges-in-dh",
                  },
                  {
                    name: "Top Colleges in UP",
                    path: "/cms?page=colleges-in-up",
                  },
                  {
                    name: "Top Colleges in Karnataka",
                    path: "/cms?page=colleges-in-kn",
                  },
                  {
                    name: "Top Colleges in Haryana",
                    path: "/cms?page=colleges-in-hn",
                  },
                  {
                    name: "Top Colleges in West Bengal",
                    path: "/cms?page=colleges-in-wb",
                  },
                  {
                    name: "All Locations",
                    path: "/cms?page=colleges-in-india",
                  },
                ],
              },
              {
                name: "Colleges by City",
                path: "/cms?page=all-mba-exams",
                children: [
                  {
                    name: "Top Colleges in Delhi/NCR",
                    path: "/cms?page=colleges-in-dh",
                  },
                  {
                    name: "Top Colleges in Pune",
                    path: "/cms?page=colleges-in-pune",
                  },
                  {
                    name: "Top Colleges in Mumbai",
                    path: "/cms?page=colleges-in-mumbai",
                  },
                  {
                    name: "Top Colleges in Bangalore",
                    path: "/cms?page=colleges-in-bangalore",
                  },
                  {
                    name: "Top Colleges in Kolkata",
                    path: "/cms?page=colleges-in-kolkata",
                  },
                  {
                    name: "Top Colleges in Chandigarh",
                    path: "/cms?page=colleges-in-chandigarh",
                  },
                  {
                    name: "Top Colleges in Dehradun",
                    path: "/cms?page=colleges-in-dehradun",
                  },
                  {
                    name: "Top Colleges in All Cities",
                    path: "/cms?page=colleges-in-cities",
                  },
                ],
              },
              {
                name: "Exams",
                path: "/cms",
                children: [
                  {
                    name: "CAT 2022",
                    path: "/cms?page=cat-2022",
                  },
                  {
                    name: "XAT 2023",
                    path: "/cms?page=xat-2023",
                  },
                  {
                    name: "MAT",
                    path: "/cms?page=mat",
                  },
                  {
                    name: "SNAP 2022",
                    path: "/cms?page=snap-2022",
                  },
                  {
                    name: "ATMA",
                    path: "/cms?page=atma",
                  },
                  {
                    name: "NMAT 2022",
                    path: "/cms?page=nmat-2022",
                  },
                  {
                    name: "CMAT 2023",
                    path: "/cms?page=cmat-2023",
                  },
                  {
                    name: "IBSAT",
                    path: "/cms?page=ibsat",
                  },
                  {
                    name: "MAH CET",
                    path: "/cms?page=mah-cet",
                  },
                  {
                    name: "All MBA Exams",
                    path: "/cms?page=all-mba-exams",
                  },
                  {
                    name: "Exam Calander",
                    path: "/cms",
                  },
                ],
              },
            ],
          },
          {
            name: "MBA",
            path: "/cms",
            children: [
              {
                name: "Colleges by State",
                path: "/cms?page=mah-cet",
                children: [
                  {
                    name: "Top Colleges in maharashtra",
                    path: "/cms?page=colleges-in-mh",
                  },
                  {
                    name: "Top Colleges in Delhi",
                    path: "/cms?page=colleges-in-dh",
                  },
                  {
                    name: "Top Colleges in UP",
                    path: "/cms?page=colleges-in-up",
                  },
                  {
                    name: "Top Colleges in Karnataka",
                    path: "/cms?page=colleges-in-kn",
                  },
                  {
                    name: "Top Colleges in Haryana",
                    path: "/cms?page=colleges-in-hn",
                  },
                  {
                    name: "Top Colleges in West Bengal",
                    path: "/cms?page=colleges-in-wb",
                  },
                  {
                    name: "All Locations",
                    path: "/cms?page=colleges-in-india",
                  },
                ],
              },
              {
                name: "Colleges by City",
                path: "/cms?page=all-mba-exams",
                children: [
                  {
                    name: "Top Colleges in Delhi/NCR",
                    path: "/cms?page=colleges-in-dh",
                  },
                  {
                    name: "Top Colleges in Pune",
                    path: "/cms?page=colleges-in-pune",
                  },
                  {
                    name: "Top Colleges in Mumbai",
                    path: "/cms?page=colleges-in-mumbai",
                  },
                  {
                    name: "Top Colleges in Bangalore",
                    path: "/cms?page=colleges-in-bangalore",
                  },
                  {
                    name: "Top Colleges in Kolkata",
                    path: "/cms?page=colleges-in-kolkata",
                  },
                  {
                    name: "Top Colleges in Chandigarh",
                    path: "/cms?page=colleges-in-chandigarh",
                  },
                  {
                    name: "Top Colleges in Dehradun",
                    path: "/cms?page=colleges-in-dehradun",
                  },
                  {
                    name: "Top Colleges in All Cities",
                    path: "/cms?page=colleges-in-cities",
                  },
                ],
              },
              {
                name: "Exams",
                path: "/cms",
                children: [
                  {
                    name: "CAT 2022",
                    path: "/cms?page=cat-2022",
                  },
                  {
                    name: "XAT 2023",
                    path: "/cms?page=xat-2023",
                  },
                  {
                    name: "MAT",
                    path: "/cms?page=mat",
                  },
                  {
                    name: "SNAP 2022",
                    path: "/cms?page=snap-2022",
                  },
                  {
                    name: "ATMA",
                    path: "/cms?page=atma",
                  },
                  {
                    name: "NMAT 2022",
                    path: "/cms?page=nmat-2022",
                  },
                  {
                    name: "CMAT 2023",
                    path: "/cms?page=cmat-2023",
                  },
                  {
                    name: "IBSAT",
                    path: "/cms?page=ibsat",
                  },
                  {
                    name: "MAH CET",
                    path: "/cms?page=mah-cet",
                  },
                  {
                    name: "All MBA Exams",
                    path: "/cms?page=all-mba-exams",
                  },
                  {
                    name: "Exam Calander",
                    path: "/cms",
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: "Computer Application",
        path: "",
        children: [
          {
            name: "BBA",
            path: "/cms?page=all-mba-exams",
          },
          {
            name: "MBA",
            path: "/cms",
          },
        ],
      },
    ],
  },
  {
    name: "Admission 2023",
    path: "/cms",
    children: [
      {
        name: "Counselling",
        path: "/cms?page=more-courses",
        children: [
          {
            name: "Ask a Question",
            path: "/cms?page=more-courses",
          },
          {
            name: "Request a Call Back",
            path: "/cms?page=more-courses",
          },
        ],
      },
      {
        name: "Compare Colleges",
        path: "/cms?page=more-courses",
      },
      {
        name: "College Predictor",
        path: "/cms?page=more-courses",
      },
      {
        name: "Education Loan",
        path: "/cms?page=more-courses",
      },
    ],
  },
];
