import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "../api/axios";
import BannersList from "../Components/BannersList";
import CollegeListItem from "../Components/CollegeListItem";
import ReactPaginate from "react-paginate";
import "../assets/css/main.css";

const CollegeList = ({ itemsPerPage = 10 }) => {
  const [urlSearchParams] = useSearchParams();
  const page = urlSearchParams.get("q") || null;
  const menu_page = urlSearchParams.get("m") || null;
  const [itemOffset, setItemOffset] = useState(0);

  const [data, setData] = useState([]);
  const endOffset = itemOffset + itemsPerPage;
  console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  const currentItems = data.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(data.length / itemsPerPage);
  useEffect(() => {
    console.log("menu_page", menu_page);
    if (!page && !menu_page) {
      console.log("returning");
    }
    let isMounted = true;
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        const response = await axios.post("/college-list", {
          q: page?.toLowerCase(),
          m: menu_page?.toLowerCase(),
        });
        console.log(response.data);
        setData(response.data.success ? response.data.data : []);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [page, menu_page]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % data.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };
  return (
    <>
      <BannersList />
      <section className="breadcrumbs ">
        <div className="container ">
          <div className="row ">
            <div className="col-auto top1">
              <div className="breadcrumbs__content ">
                <div className="breadcrumbs__item">
                  <a href="#">Home</a>
                </div>

                <div className="breadcrumbs__item">
                  <a href="#">All courses</a>
                </div>

                <div className="breadcrumbs__item">
                  <a href="#">User Experience Design</a>
                </div>

                <div className="breadcrumbs__item">
                  <a href="#">User Interface</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="page-header -type-1">
        <div className="container">
          <div className="page-header__content">
            <div className="row">
              <div className="col-auto">
                <div className="media">
                  <h1 className="page-header__title">
                    {page?.split("+") || menu_page?.split("+")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="layout-pt-md layout-pb-lg row no-gutters d-flex flex-nowrap align-items-center">
        <div className="container">
          <div className="row y-gap-50">
            <div className="col-16 col-sm-16">
              <div className="accordion js-accordion">
                <div className="accordion__item">
                  <div className="row y-gap-20 items-center justify-between pb-30">
                    <div className="col-auto ">
                      <div className="text-14 lh-12 media-2">
                        Showing
                        <span className="text-dark-1 fw-500">
                          {" "}
                          {currentItems.length}
                        </span>{" "}
                        out of total results
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row y-gap-60">
                <div className="row">
                  {currentItems.map((item) => {
                    return <CollegeListItem item={item} key={item.id} />;
                  })}
                </div>
              </div>

              <div className="row justify-center pt-90 lg:pt-50">
                <div className="col-auto">
                  {currentItems.length ? (
                    <ReactPaginate
                      pageCount={data.length}
                      pageRangeDisplayed={10}
                      onPageChange={handlePageClick}
                      previousLabel="<"
                      nextLabel=">"
                      containerClassName=""
                      className="pagination -buttons media-8"
                      pageClassName="pagination__count"
                      activeLinkClassName="-count-is-active"
                      previousLinkClassName="pagination__button -prev"
                      nextLinkClassName="pagination__button -next"
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default CollegeList;
