import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../../../api/axios"; 

const XAT2023 = () => {
  const [data, setData] = useState([]);
useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios("/display-table");
        setData(response.data.display_data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);


  return (
    <div>
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>
            XAT 2023: Exam on Jan 8, 2023; Registration is Open from Aug 10,
            2022; XLRI &amp; 160 MBA Colleges to Accept XAT Score
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT 2023 will be held on January 8, 2023 and the XAT registration
          process began on August 10, 2022. Registrations for XAT exam 2023 will
          be held online and the registration fee for this year is Rs. 2,000
          plus Rs. 200 for every XLRI program.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Xavier Aptitude Test (XAT) is one of the largest MBA Entrance Exams
          after CAT. Every year around 1,00,000 candidates from all disciplines
          including Engineering, Commerce, Humanities, Science, Law etc. apply
          and appear in XAT exam to get into the various MBA programs offered by
          top B-schools. &nbsp;XLRI, Jamshedpur is conducting XAT since past 72
          years on behalf of XAMI. Further in this article, you will read all
          key points to plan your XAT 2023, including important dates to apply
          for XAT 2023 exam, syllabus &amp; pattern, preparation, and much more.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <h4>Latest Update</h4>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <table style={{ border: "1px solid black", width:"63.5%" }} id="display-data">
  <thead>
    <tr>
      <th colSpan={2} style={{ border: "1px solid black" }}>
        MBA Entrance Exam &amp; MBA/PGDM Admissions – Apply Now
      </th>
    </tr>
  </thead>
  <tbody id="display-data-body">
    {data.map((item) => {
      return (
        <tr>
          <td>
            <a href={item.url} target="_blank">
              {item.display_name}
            </a>
          </td>
          <td>
          <a href={item.url} target="_blank">
              {item.display_name}
            </a>

          </td>
        </tr>
      );
    })}
  </tbody>
</table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>
            <u>Table of Content</u>
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>About XAT Examination</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT Examination 2022 – Highlights</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT 2023 Important Dates</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT 2023 Eligibility Criteria</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT 2023 Registrations Process</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT 2023 Exam Centers</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT Exam Pattern</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT Exam Syllabus </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT 2023 Exam Preparation Tips</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: '"Courier New"' }}>
            o
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>
            XAT 2023 Quantitative Aptitude &amp; Data Interpretation Preparation
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: '"Courier New"' }}>
            o
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>XAT 2023 Verbal &amp; Logical Ability Preparation</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: '"Courier New"' }}>
            o
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>XAT 2023 Decision Making (DM) Preparation</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "0in",
            marginLeft: "1.0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: '"Courier New"' }}>
            o
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>XAT 2023 GK Preparation</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT Admit Card 2023</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT Exam Day Guidelines</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT 2023 Answer Key</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT 2023 Result</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT 2023 Selection Process</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>XAT Cut Offs 2023</b>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>FAQs XAT Exam 2023</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>About XAT Examination</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Xavier Aptitude Test (XAT) is the Admission Test for admission to MBA
          / PGDM programs in XLRI and other campuses of XAMI (Xavier Association
          of Member Institutes) such as XISS - Ranchi, XIME - Bangalore etc.
          Other top B-schools like IMT Ghaziabad, IMI New Delhi, LBSIM Delhi,
          TAPMI Manipal and 160 other top MBA colleges in India accept XAt
          score. Overall, XAT score is accepted by 1000 plus b-schools across
          India. XLRI will offer admission to its PGDM programs offered in
          Jamshedpur and Delhi campuses only on the basis of XAT exam score.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT 2023 will be held on January 8, 2023 and registration process
          began on August 10, 2022. Registrations for XAT exam 2023 will be held
          online and the registration fee for this year is Rs. 2,000 INR plus
          Rs. 200 for every XLRI program. There are more than 70 cities and more
          than 100 venues where XAT 2023 exam will be conducted in
          India.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT Examination 2023 – Highlights</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        ></p>
        <table cellSpacing={0} cellPadding={0} align="left">
          <tbody>
            <tr>
              <td width={1} height={0} />
            </tr>
            <tr>
              <td />
              <td>
                <img
                  src="2.%20XAT%202023_files/image001.jpg"
                  width={628}
                  height={260}
                />
              </td>
            </tr>
          </tbody>
        </table>
        &nbsp;
        <p />
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <br clear="ALL" />
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    <u>Particulars</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    <u>Details</u>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  XAT Full Form
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Xavier Admission Test
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Conducting institute
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Xavier School of Management (XLRI) Jamshedpur
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Level
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  National Level Post Graduate
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Language
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  English
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Frequency
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Once in a Year
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Date
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jan 8, 2023
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Fees
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 2,000 + Rs. 200 for every XLRI program.
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Eligibility Criteria
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  3 year’s bachelor’s degree in any discipline from a recognized
                  university with aggregate 50% marks
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Number of Applications
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Around 1,00,000
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Purpose
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Admission into XLRI Jamshedpur, XLRI Delhi and other 160
                  member top B-school.
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Course Offered
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  MBA
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  PGDM
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  XAT Syllabus
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Decision Making
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Verbal &amp; Logical Ability
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Quantitative Ability &amp; Data Interpretation
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  General Knowledge
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Essay Writing
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  XAT Pattern
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Computer Based Test (CBT) with MCQs
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  No of Questions
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  100
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  No. of Test Cities
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  78
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  XAT Official Website
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <a href="https://xatonline.in/">https://xatonline.in/</a>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Helpdesk No.
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  18002674008
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Age Criteria
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  No Age Limit
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "142.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={190}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Work Experience
                </p>
              </td>
              <td
                style={{
                  width: "336.15pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={448}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Not Mandatory
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>XAT 2023 Important Dates</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    <u>XAT Exam 2023 Events</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    <u>Important Dates</u>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Registration Start
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  10 August, 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Registration End (without late fee)
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30 November, 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Registration (with late fee)
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  To be announced
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Admit Card Release
                  Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  20 December, 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Mock Test Release
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Last week of December, 2022
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Examination
                  Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  8 January, 2023
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Answer Key and Response Sheet Release{" "}
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Second week of January, 2023
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  XAT Result
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  31 January, 2023
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "200.65pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={268}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  XAT 2023 cut off announcement{" "}
                </p>
              </td>
              <td
                style={{
                  width: "159.25pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={212}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Last week of January, 2023
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT 2023 Eligibility Criteria</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The minimum eligibility criterion for XAT 2023 exam is graduation in
          any stream. Detailed XAT eligibility criteria are listed below:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Candidates must hold a bachelor’s degree of minimum three years
          duration or equivalent in any discipline from a recognised university
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          XAT does not require any minimum percentage of marks in graduation to
          register and apply for XAT exam
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Students in their final year of graduation can also apply
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Minimum 15 years of academic study till bachelor’s degree is required.
          It means you must have pursued or pursuing minimum three years of
          graduation degree
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          There is no upper age limit for the applicants.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <table style={{ border: "1px solid black", width:"63.5%" }} id="display-data">
  <thead>
    <tr>
      <th colSpan={2} style={{ border: "1px solid black" }}>
        MBA Entrance Exam &amp; MBA/PGDM Admissions – Apply Now
      </th>
    </tr>
  </thead>
  <tbody id="display-data-body">
    {data.map((item) => {
      return (
        <tr>
          <td>
            <a href={item.url} target="_blank">
              {item.display_name}
            </a>
          </td>
          <td>
          <a href={item.url} target="_blank">
              {item.display_name}
            </a>

          </td>
        </tr>
      );
    })}
  </tbody>
</table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT 2023 Registrations Process</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT registration process is online and can be completed by following
          the below mentioned steps at XAT 2023 official website. XAT exam 2023
          registration dates are open from 10 August, 2022 to 30 November, 2022.
          The steps to register and apply for the XAT exam are:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          1.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Register yourself on official XAT 2023 Exam website -{" "}
          <a href="https://xatonline.in/">https://xatonline.in/</a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          2.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Generate User ID and Password
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          3.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Verify Email ID on receipt of message from exam centre
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          4.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Fill all the pages of XAT Application Form online
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1.0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          a.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Personal Details: Date of Birth, Category, Nationality, Names of
          Parents, Permanent/ Current Address, Contact details.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1.0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          b.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Academic Details: Class 10th marks, class 12th marks, graduation and
          postgraduation scores, professional degree/ course details.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1.0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          c.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Work Experience Details: Full-time work experience details till March
          31, 2022.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1.0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          d.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          XLRI Program Preference: Candidates willing to apply for XLRI programs
          need to submit their choice of program- Business Management (BM),
          Human Resource Management (HRM), Fellow Management Program.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1.0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          e.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          XAT Exam City Preference: Candidates can select 2 XAT exam cities from
          the drop-down list based on their convenience.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          5.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Upload Required Documents on relevant pages of XAT Application Form
          2023
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          6.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Photograph: Recent photograph in JPG/ JPEG format. File size should be
          between 20kb to 50kb. The recommended dimension is 200 x 230 pixels.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          7.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Signature: Signature done in black ink on white paper. File size
          should be between 20kb to 50kb. The recommended dimension is 140 x 60.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          8.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Pay XAT Application Fee of Rs.2000/- Online
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          9.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Submit XAT Application Form Online
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT 2023 Exam Centers</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT 2023 will be held in around 80 cities of the country and each city
          will have one or more XAT exam centers. Final list of XAT 2023 exam
          centers is expected to be out soon. Tentative list of cities are:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Agra
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Ahmedabad
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Aurangabad (Maharashtra)
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Allahabad
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Ambala
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Amravati
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Amritsar
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Bengaluru
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Berhampur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Bhatinda
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Bhilai Nagar
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Bhopal
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Bhubaneswar
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Bokaro
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Chandigarh / Mohali
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Chennai
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Coimbatore
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Cuttack
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Dehradun
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Delhi-NCR
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Dhanbad
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Dibrugarh
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Durgapur/Asansol
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Ernakulam
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Gandhinagar
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Goa
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Gorakhpur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Guwahati
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Gwalior
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Hooghly
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Hubli
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Hyderabad
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Indore
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Jabalpur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Jaipur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Jammu
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Jamshedpur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Kannur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Kanpur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Kolkata
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Kota
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Kottayam
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Kurnool
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Kurukshetra
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Lucknow
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Ludhiana
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Madurai
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Mangalore
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Mysore
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Nagpur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Nashik
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Patna
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Pune
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Raipur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Rajahmundry
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Ranchi
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Roorkee
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Rourkela
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Sambalpur
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Siliguri
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Surat
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Thiruvananthapuram
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Tiruchirappalli
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Tirupathi
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Tiruvallur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Udaipur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Udupi
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Vadodara
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Varanasi
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Vijayawada
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Visakhapatnam
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "119.7pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Warangal
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Tejpur
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Jorhat
                </p>
              </td>
              <td
                style={{
                  width: "119.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={160}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Rajkot
                </p>
              </td>
            </tr>
            <tr>
              <td
                colSpan={2}
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Shillong
                </p>
              </td>
              <td
                colSpan={2}
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Naharlagun
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT Exam Pattern</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          It is important for candidates to understand the pattern of the XAT
          question paper, before appearing for the test. They must be aware of
          the marking scheme, time duration, and the number of questions to be
          solved, to get admission into their targeted B-school.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Xavier Aptitude Test is online computer based exam. The aggregate
          maximum marks in the exam are 101 for 101 questions divided in 5
          sections. Each correct answer for MCQ is awarded +1 mark and each
          wrong answer will attract the penalty of -0.25 mark. There is no
          negative marking for GK section. Essay is evaluated if the student is
          shortlisted for selection process and is used during the Personal
          Interview Round. Total time duration for the exam is 3 hours 10
          minutes.{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT Exam pattern with sectional division of XAT questions and XAT exam
          time duration is as below:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "42.85pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={57}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Parts</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "148.7pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={198}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Exam Sections</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Number of Questions</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Number of Marks</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Overall Time Duration</u>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                rowSpan={3}
                style={{
                  width: "42.85pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={57}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Part 1
                </p>
              </td>
              <td
                style={{
                  width: "148.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={198}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Verbal and Logical Ability
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  26
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  26
                </p>
              </td>
              <td
                rowSpan={3}
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  165 minutes (no sectional time limit)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "148.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={198}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Decision Making
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  21
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  21
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "148.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={198}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Quantitative Ability &amp; Data Interpretation
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  28
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  28
                </p>
              </td>
            </tr>
            <tr>
              <td
                rowSpan={2}
                style={{
                  width: "42.85pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={57}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Part 2
                </p>
              </td>
              <td
                style={{
                  width: "148.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={198}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  General Knowledge
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  25
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  25
                </p>
              </td>
              <td
                rowSpan={2}
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  25 minutes
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "148.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={198}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Essay
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  1
                </p>
              </td>
              <td
                style={{
                  width: "95.75pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={128}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  (To be evaluated upon shortlisting)
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>
            <span style={{ color: "white" }}>XAT Exam Syllabus</span>
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT syllabus consists of all the important topics of Verbal &amp;
          Logical Ability, Quantitative Ability &amp; Data Interpretation and
          General Knowledge. Decision Making is a unique section for which there
          is no syllabus. Preparation for XAT is highly based on previous year
          questions. Some of the important topics which occupy the maximum
          weightage in XAT are given below:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "211.85pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={282}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Syllabus</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "266.95pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={356}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Important Topics</u>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "211.85pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={282}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Verbal and Logical Ability
                </p>
              </td>
              <td
                style={{
                  width: "266.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={356}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Reading Comprehension, Para Jumbles, Para Completion,
                  Vocabulary, Grammar, Error Detection, Sentence Completion,
                  Synonyms &amp; Antonyms, Idioms &amp; Phrases, Odd One Out,
                  etc.
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "211.85pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={282}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Decision Making
                </p>
              </td>
              <td
                style={{
                  width: "266.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={356}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Business Decisions, Analytical Reasoning, Ethical Dilemma,
                  Situational Conditions, Grouping Test, Data Arrangement Test,
                  Decision Making in a situation
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "211.85pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={282}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Data Interpretation and Quantitative Ability
                </p>
              </td>
              <td
                style={{
                  width: "266.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={356}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Number System, Work &amp; Time, Probability, Geometry,
                  Mensuration, Permutation &amp; Combination, Percentage &amp;
                  Ratios, Mixtures &amp; Alligation, Algebra, Profit and Loss,
                  Speed &amp; Distance, Quadratic Equations, Logarithm, Surds
                  &amp; Indices, Statistics, Trigonometry, DI Graphs, Tables
                  &amp; Charts.
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "211.85pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={282}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  General Knowledge
                </p>
              </td>
              <td
                style={{
                  width: "266.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={356}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Static GK and Current Affairs: Politics, Economics, History,
                  Geography, Sports, Business Affairs, National &amp;
                  International Affairs, and States, etc.
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "211.85pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={282}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Essay
                </p>
              </td>
              <td
                style={{
                  width: "266.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={356}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  An essay of 250 words on any topic, such as current affairs,
                  social &amp; economic issues, abstract, science &amp;
                  technology, business &amp; management-oriented topics, etc.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Note: General Knowledge marks are not taken into consideration while
          calculating percentile and in the counseling procedure. However, some
          of the institutes might consider GK's marks in selection process.{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT 2023 Exam Preparation Tips</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT being one of the tougher MBA entrance exams among its peers,
          preparation should ideally be for six to nine months. It comprises
          four sections: Decision Making, Verbal Ability and Logical Reasoning,
          Quantitative Ability &amp; Data Interpretation and General Knowledge.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          In XAT, all sections need equal attention and time management as
          sectional cut offs play an important role in selection to XLRI,
          Jamshedpur. So, divide your study hours among these sections equally
          and focus on the following parameters while preparing:{" "}
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Follow the exam pattern to understand the structure, number and type
          of questions.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Scan thoroughly the XAT exam Syllabus and sections.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Identify your strengths and weaknesses and make a list of topics as
          per it.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Join online study groups on platforms such as Facebook, YouTube,
          Telegram, etc.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Collect relevant study material, test sets and recommended books for
          XAT preparation.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Make day-wise plan as per topics and syllabus by allotting time to
          each section.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Practice two-three questions from the topic studied on the same day to
          assess how much you have learnt.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Start from topics wise test series to be followed by subject wise and
          then overall.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Increase the speed gradually to cover maximum number of topics each
          day.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>
            XAT 2023 Quantitative Aptitude &amp; Data Interpretation Preparation
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT 2023 Question paper will comprise 28 questions from various topics
          of the QADI syllabus. The major tips to ace XAT QADI preparation are
          as follows:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Study and Revise basic concepts and formulas like important
          ratio-percentage conversion ratios, quadratic equations, squares and
          square roots, cubes and cube roots, etc.{" "}
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Practice at least 20 questions every day to ensure consistency and
          build retention power.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Refer to online study platforms to clear doubts, and to learn short
          tricks and formulas.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Choose any good Quant &amp; DI book for XAT preparation by writers
          like Arun Sharma.{" "}
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Practice sectional as well as full-length XAT mock tests and sample
          papers.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          For XAT DI preparation, solve at least 2-3 DI sets every week from
          different types of data sets including, pie charts, bar graphs, line
          graphs, tabular data, and caselets.{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT 2023 Verbal &amp; Logical Ability Preparation</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT 2023 Verbal &amp; Logical Ability will comprise 26 MCQs- about 4
          RC passages, 2-3 poem-based comprehensions, 2-3 para jumbles, 4-5
          critical reasoning questions, and miscellaneous grammar and vocabulary
          based questions. The most important tips to keep in mind for XAT VALR
          preparation are given below:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To increase vocabulary, refer to the book titled’ Word Power Made
          Easy’ by Norman Lewis and also read at least 1-2 newspaper/ magazine/
          online articles.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To learn grammar concepts and practice questions on error detection,
          para jumbles, fill in the blanks, etc.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Refer to some of the most recommended VA books like VARC by Ajay
          Singh, How to Prepare for VARC by Arun Sharma, and Objective English
          by SP Bakshi.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To increase the reading speed for RC passages, firstly start reading
          short passages (300-400 words) from reference books and gradually
          increase the length of the passages.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Solve as many sample papers and online quizzes as possible to be able
          to practice maximum type of questions.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT 2023 Decision Making (DM) Preparation</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          There is no particular study material or method to prepare for XAT DM
          section. However, candidates preparing for XAT 2023 must keep in mind
          the following tips to prepare for XAT Decision Making section:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          The best way to prepare for this section is to solve XAT previous year
          question papers and figure out the approach for answering such
          questions accurately.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Based on the analysis of previous year papers, it is seen that more
          than 50% of the questions were related to business decision making and
          the rest were based on ethical dilemmas.{" "}
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Do not let your bias affect your answers as you are required to choose
          the best alternative from the given options.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          To answer questions on ethical dilemmas, first, try to understand the
          ethics of the given situation and avoid choosing passive options.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT 2023 GK Preparation</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT GK forms part 2 of XAT question paper and comprises 25 questions
          from static general knowledge and current affairs. The most important
          points to keep in mind while preparing for XAT 2023 are mentioned
          below for your reference.
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          For Static GK, refer to any good book like Lucent’s GK, Manorama Year
          Book, Arihant’s GK, etc.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Read newspapers (The Hindu, Indian Express, Economic Times), Magazines
          (Frontline, Business Line, Yojana), and online news articles to stay
          updated with the current events of national and international
          importance.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          The most important step is to take notes of daily current affairs to
          ease last-minute revision.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <table style={{ border: "1px solid black", width:"63.5%" }} id="display-data">
  <thead>
    <tr>
      <th colSpan={2} style={{ border: "1px solid black" }}>
        MBA Entrance Exam &amp; MBA/PGDM Admissions – Apply Now
      </th>
    </tr>
  </thead>
  <tbody id="display-data-body">
    {data.map((item) => {
      return (
        <tr>
          <td>
            <a href={item.url} target="_blank">
              {item.display_name}
            </a>
          </td>
          <td>
          <a href={item.url} target="_blank">
              {item.display_name}
            </a>

          </td>
        </tr>
      );
    })}
  </tbody>
</table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT Admit Card 2023</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT admit card 2023 download process will start from December 20, 2022
          onwards. The admit cards will be released online at the exam website.
          Check below the steps to download XAT 2023 admit card which is a
          mandatory document to be carried to the exam hall:{" "}
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Visit the official website of XAT 2023 -{" "}
          <a href="https://xatonline.in/">https://xatonline.in/</a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Click on 'Download admit card' tab{" "}
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Enter details like XAT User ID and date of birth and click on 'login'
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Click on admit card tab, download the pdf and take a printout&nbsp;{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT Exam Day Guidelines</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT candidates must follow the guidelines set by the examination
          authority. Given below are some key XAT exam day guidelines,
          candidates must follow:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Reach the exam centre before the reporting time
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Do not carry any electronic item like mobile phone, watch, calculator,
          etc. to the exam centre
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Do not carry any stationary item or eatables to the exam centre
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Documents to carry on exam day</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          You need to carry the following documents for identification and
          verification purpose to the exam centre:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          XAT admit card
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Aadhar card / Voter ID / Passport, College/University ID / Driver’s
          license
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;XAT 2023 Answer Key</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT 2023 Answer Key will be released wthin 2-3 days in second week of
          January 2023 after the XAT exam. Last year XAT answer key was released
          on January 3, 2022 within a day of conducting XAT exam on Jan 2. You
          can check your Answer Keys on XAT official website. Only those
          candidates who have appeared in XAT 2023 can view and download both
          the Official XAT Answer Key as well as their Individual Responses to
          XAT Questions. It will help you calculate your XAT score and likely
          percentile.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT 2023 Result</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Xavier School of Management (XLRI) will release the result of XAT 2023
          in the last week of January 2023. Candidates would be able download
          their XAT 2023 scorecard and check their sectional and overall
          percentile till March 31, 2023.{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          A student can also apply for hard copy of the XAT scorecard 2023 by
          paying Rs 300 in the form of Demand Draft (DD) in favor of XLRI
          Jamshedpur, payable at Jamshedpur.{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>How to check XAT Result 2023?</b>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Go to the XAT official website - xatonline.in
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Click on the login tab on the page.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Type the registered email and Password.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Click on the "Score Card" button.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          The result will appear on the display.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Download and save it in PDF form.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT 2023 Selection Process</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT 2023 Selection Process will begin after the declaration of result.
          XLRI does not carry out a centralized selection process. Every XAT
          score accepting institute follows its own selection process and
          criteria.
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          XLRI XAT selection process will include GD-PI rounds. Only candidates
          shortlisted on the basis of XAT cut offs will be called for GD-PI
          rounds.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Post GD-PI rounds, XLRI will prepare the final merit list based on
          various parameters: XAT 2023 score, XAT GD-PI scores, work experience,
          past academic scores, and GK &amp; Essay section scores.{" "}
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          The weightage assigned to XAT score &amp; GD/PI scores in the
          preparation of the final merit list will be 60% &amp; 25%
          respectively.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Selection process of other XIMA campuses and XAT score accepting
          Institutes will vary and would depend upon the college own selection
          process.{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>XAT Cut Offs 2023</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          XAT participating institutes will release admission cut offs to
          shortlist candidates for GD/PI after XAT 2023 result declaration. The
          estimated cut off scores and percentiles of some of the top XAT
          institutes basis previous year trends are as follows:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>College</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>XAT cut off percentile</u>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  XLRI-Jamshedpur
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  92 – 94
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  XIMB-Bhubaneswar
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  90 – 93
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  SPJIMR-Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  93 – 95
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IMT-Ghaziabad
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  90 – 92
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  GIM-Goa
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  85 – 90
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  TAPMI-Manipal
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80 – 85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IRMA-Anand
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80 – 85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  MICA-Ahmedabad
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80 – 85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Great Lakes Institute of Management
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80 – 85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  LIBA-Chennai
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80 – 85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "239.4pt",
                  border: "solid black 1.0pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  KJ Somaiya-Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "239.4pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1.0pt",
                  borderRight: "solid black 1.0pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={319}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  85 – 90
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Note: The cut off percentiles mentioned in the above table are of
          General category.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <table style={{ border: "1px solid black", width:"63.5%" }} id="display-data">
  <thead>
    <tr>
      <th colSpan={2} style={{ border: "1px solid black" }}>
        MBA Entrance Exam &amp; MBA/PGDM Admissions – Apply Now
      </th>
    </tr>
  </thead>
  <tbody id="display-data-body">
    {data.map((item) => {
      return (
        <tr>
          <td>
            <a href={item.url} target="_blank">
              {item.display_name}
            </a>
          </td>
          <td>
          <a href={item.url} target="_blank">
              {item.display_name}
            </a>

          </td>
        </tr>
      );
    })}
  </tbody>
</table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>FAQs XAT Exam 2023</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is XAT and when is XAT 2023?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Xavier Aptitude Test (XAT) is the Admission Test for admission to
          MBA / PGDM programs in XLRI and 160 associate colleges. It is held
          once in a year and is attended by around 1,00,000 student every year.
          XAT 2023 will be held on Sunday January 8, 2023 in morning time slot
          from 10:00 AM to 01:10 PM.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: When will XAT registrations 2023 begin?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: XAT registrations 2023 began on August 10 and will end on
          November 30, 2022. The XAT exam fees is Rs. 2,000 which has to be paid
          online via debit/credit card, net banking or e-wallets.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are all the sections available in the XAT 2023 syllabus?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: There are five sections available in the XAT exam syllabus,
          namely:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          1.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Decision Making
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          2.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Verbal &amp; Logical Ability
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          3.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Quantitative Ability &amp; Data Interpretation
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          4.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          General Knowledge
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          5.
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          Essay Writing
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is the marking scheme for XAT exam? Is there negative marking?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          AnsThe aggregate maximum marks in XAT are 101 for 101 questions
          divided in 5 sections. Each correct answer for MCQ is awarded +1 mark
          and each wrong answer will attract the penalty of -0.25 mark. There is
          no negative marking for GK section. Essay is evaluated if the student
          is shortlisted for selection process and is used during the Personal
          Interview Round.{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: How many times can I attempt XAT exam?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: There is no restriction on number of times one can attempt XAT
          nor there is any age limit. However, XAT is conducted annually, so the
          opportunity to appear for it in a particular academic year is only
          once.{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Where can I check XAT 2023 answer key?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The XAT 2023 answer key will be released on the exam website{" "}
          <b>www.xatonline.in</b>
          in the form of response sheet. Candidates will be able to download
          their answer keys and response sheets by logging into their XAT
          account.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: When will XAT 2023 result be released?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The XAT 2023 result will be released on January 31, 2023;
          however, it can be released before January 31 too. The result
          declaration date will be announced after the exam has been conducted.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: How many candidates appear for XAT?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: XAT is the second most preferred MBA entrance exam after CAT.
          Around 80,000 to 1,00,000 student appear for it every year.{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Is XAT tougher than CAT?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: XAT and CAT both are tough in comparison to other MBA entrance
          exams. Many candidates consider XAT to be more difficult exam than
          CAT. The QA section of XAT is considered more difficult as per experts
          as well as students who appear for the exam.{" "}
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Do XAT cut offs differ for different courses of XLRI?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Yes, admission cut offs for various PGDM courses of XLRI are
          different. The XAT cut offs vary for male, female, engineering and
          non-engineering candidates as well. Last year, the XAT cut off for
          male category was 93 percentile while for female category, it was 90
          percentile.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: In how many months can I prepare for XAT 2023?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Six to nine months is considered as an ideal preparation time for
          XAT exam. However, candidates must make their own exam preparation
          approach as per their strengths and weaknesses over different subjects
          and topics.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Will there be any sectional time-limit in XAT 2023 exam?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Yes, XAT exam has two sectional time-limits. Part 1 is to be
          completed within 165 minutes and Part 2 is to be completed within 25
          minutes. However, in Part 1 there are three subjects and in Part 2
          there are two subjects and there is no limit within these sections.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: Can a second year graduation student apply for XAT 2023?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: No, only those students who have either completed their
          graduation or are in the final year of graduation can apply for XAT
          2023.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are the scholarship schemes in XLRI, Jamshedpur?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: XLRI Jamshedpur offers various scholarships to meritorious and
          economically backward students. The list of scholarships offered last
          year is given below:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Aditya Birla Scholarship
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Kalyan Ganguly XEF Scholarship
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Capital First Scholarship
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Naveen Jain &amp; Distinguished Alumni Award
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Government of India for (ST) Students
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Vasantha Sankaran Scholarship
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          OP Jindal Engineering &amp; Management
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          XLRI Diamond Jubilee (Seniors)
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          XLRI Diamond Jubilee (Juniors)
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          T Thomas
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Alumni Scholarship
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Pirojsha Godrej
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Alumni Scholarship
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Joseph M Sciortino (for ST Students)
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7.0pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          Geeta Saxena Memorial
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. Does XLRI release different XAT cut offs for BM and HRM programs?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Yes, XAT cut off varies depending on the XLRI programs- BM and
          HRM. Also, the cut off for these programs differs for males and
          females, engineers and non-engineers. The cut off for XLRI usually
          ranges between 91 - 95 percentiles.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. Are there sectional cut offs for XLRI admission through XAT?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Yes, XLRI releases sectional XAT cutoffs. Candidates need to
          secure the minimum marks in each of the sections along with the
          overall cutoff to be eligible for a interview call from XLRI.{" "}
        </p>
      </div>
    </div>
  );
};
export default XAT2023;
