import BannersList from "../Components/BannersList";
import "../index.css";

const CustomPage = (props) => {
  return (
    <>
      <BannersList custom={true} />

      <div style={{ backgroundColor: "rgba(241,242,244,.51)" }}>
        <div
          style={{
            width: "90%",
            display: "block",
            position: "relative",
            right: "100px",
            top: "115px",
            marginBottom: "130px",

            // margin: "0 auto",
            marginLeft: "270px",
          }}
        >
          <div
            className="custom-page"
            style={{
              display: "block",
              paddingLeft: "20px",
              paddingTop: "10px",
              width: "70%",
              paddingRight: "20px",
            }}
          >
            {props.children}
          </div>
        </div>
        <BannersList custom={true} />
      </div>
    </>
  );
};
export default CustomPage;
