import axios from "axios";
export default axios.create(
  {
    // baseURL: "http://localhost:3001/api",
    baseURL: "http://collagefaq.webspeed.online/api",
  },
  {
    withCredentials: true,
  }
);
