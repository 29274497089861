import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../../../api/axios"; 

const ATMA = () => {
  const [data, setData] = useState([]);
useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios("/display-table");
        setData(response.data.display_data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <div className="WordSection1">
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>
            ATMA Exam 2023: Dates, Admit Card, Registration, Eligibility,
            Syllabus, Results, Pattern
          </b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          ATMA (AIMS Test for Management Admissions) is a national-level
          entrance exam conducted by AIMS (Association of Indian Management
          School). It is a single-window test held for admission to various
          post-graduate programs like MBA, PGDM, MMS, MCA etc. The exam is
          scheduled 4-5 times in a year.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The Associated of Indian Management School (AIMS) was founded in 1988
          as a registered society. AIMS is a non-profit professional
          organization which has grown into a powerful network of over 740 top
          management schools of national and international repute in the country
          including IIMs, ISB, Xavier Institutes, Welingkar, MDI, S P Jain,
          ICFAI and NMIMS.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Next ATMA exam date is tentatively scheduled in 3rd week of February
          2023. Registration &amp; Application Process will start by 1st Week of
          December 2022. In this article, you will read all about ATMA 2023 exam
          dates and schedule, How to Register and apply, ATMA syllabus &amp;
          exam pattern, Cut off and much more.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <h4>Latest Update</h4>
        <ul>
          <li />
          <li />
          <li />
          <li />
        </ul>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>
            <u>Table of Content</u>
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>About ATMA Examination</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA Examination – Highlights</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA 2023 Important Dates</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA 2023 Eligibility Criteria</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA Application Process </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA 2023 Exam Centers</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA Exam Pattern</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA Exam Syllabus</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA Preparation Tips</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA 2023 Admit Card</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA 2023 Exam Day Guidelines</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA 2023 Result</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA 2023 Counseling</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>ATMA 2023 Cut Off Marks</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>MBA / PGDM Colleges Accepting ATMA Score: City-Wise</b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: '"Courier New"' }}>
            o
            <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
          </span>
          <b>Top MBA / PGDM Colleges in Delhi-NCR Accepting ATMA </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: '"Courier New"' }}>
            o
            <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
          </span>
          <b>Top MBA / PGDM Colleges in Mumbai Accepting ATMA </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: '"Courier New"' }}>
            o
            <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
          </span>
          <b>Top MBA Colleges in Bangalore Accepting ATMA </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: '"Courier New"' }}>
            o
            <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
          </span>
          <b>Top MBA Colleges in Pune Accepting ATMA </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: '"Courier New"' }}>
            o
            <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
          </span>
          <b>Top MBA Colleges in Hyderabad Accepting ATMA </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: '"Courier New"' }}>
            o
            <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp; </span>
          </span>
          <b>Top MBA Colleges in Kolkata Accepting ATMA </b>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          <b>FAQs – ATMA Exam 2023</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>About ATMA Examination</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          AIMS Test for Management Admissions or ATMA is a national-level exam
          conducted by the Association of Indian Management Schools (AIMS) for
          admission to MBA, PGDM, MCA and PGDBA courses. More than 750
          management institutes across India accept ATMA scores for admission to
          their management programmes. ATMA question paper comprises six
          sections with 30 questions in each section.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          ATMA Exam is conducted multiple times a year, across 5 sessions –
          February Session, April Session, May Session, July Session, and August
          Session. Questions in the exam are asked from Analytical Reasoning,
          Quantitative and Verbal Skills. ATMA is a moderate difficulty level
          exam leading to admission in top B-schools like Welingkar, VIT, XIMR,
          SDMIMD, JIMS, Jaipuria among others.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <table style={{ border: "1px solid black", width:"63.5%" }} id="display-data">
  <thead>
    <tr>
      <th colSpan={2} style={{ border: "1px solid black" }}>
        MBA Entrance Exam &amp; MBA/PGDM Admissions – Apply Now
      </th>
    </tr>
  </thead>
  <tbody id="display-data-body">
    {data.map((item) => {
      return (
        <tr>
          <td>
            <a href={item.url} target="_blank">
              {item.display_name}
            </a>
          </td>
          <td>
          <a href={item.url} target="_blank">
              {item.display_name}
            </a>

          </td>
        </tr>
      );
    })}
  </tbody>
</table>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA Examination – Highlights</b>
        </p>
        <table
          className="MsoTableGrid"
          style={{
            width: "473.4pt",
            borderCollapse: "collapse",
            border: "none",
          }}
          width={631}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    <u>Particulars</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <b>
                    <u>Details</u>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  ATMA Full Form
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  AIMS Test for Management Admissions
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Conducting institute
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Association of Indian Management School
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Level
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  National Level Post Graduate
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Language
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  English
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Frequency
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  4 – 5 times in a year
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Date (February Session)
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  3rd Week of February 2023
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Fees
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rs. 1,600 (50% Fee Concession for NE States’ candidates; 25%
                  for female candidates)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Eligibility Criteria
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  3 year’s bachelor’s degree in any discipline from a recognized
                  university with aggregate 50% marks
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Purpose
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Admission to 750+ partner MBA / PGDM colleges
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Course Offered
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  MBA
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  PGDM
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  &nbsp;
                </p>
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  ATMA Syllabus
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Analytical Reasoning Skills
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Verbal Skills
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Quantitative Skills
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  ATMA Pattern
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Computer Based Test (CBT) with MCQs
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  No of Questions
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  180
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  No. of Test Cities
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  55 (tentative)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  ATMA Official Website
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  <a href="https://www.atmaaims.com">
                    https://www.atmaaims.com
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Exam Helpdesk No.
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  040-23417876, 48544057
                </p>
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  <a href="mailto:atma.secretariat@gmail.com">
                    atma.secretariat@gmail.com
                  </a>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Age Criteria
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  No Age Limit
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "149.05pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={199}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Work Experience
                </p>
              </td>
              <td
                style={{
                  width: "324.35pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={432}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Not Mandatory
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>ATMA 2023 Important Dates</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p className="MsoNormal">
          Check out the table below to know the tentative exam dates of February
          2023 session of ATMA:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "159.6pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>ATMA Exam Event</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Start Date</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>End Date</u>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "159.6pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  ATMA February Exam Notification Release
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  2<sup>nd</sup> Week of December, 2022
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  -
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "159.6pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  ATMA Registration 2023
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  2<sup>nd</sup> Week of December, 2022
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  3<sup>rd</sup> Week of February, 2023
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "159.6pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  ATMA Registration Fee Payment
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  2<sup>nd</sup> Week of December, 2022
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  3<sup>rd</sup> Week of February, 2023
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "159.6pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  ATMA Admit Card Download
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  3<sup>rd</sup> Week of February, 2023
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  4<sup>th</sup> Week of February, 2023
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "159.6pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  ATMA Mock Test
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  3<sup>rd</sup> Week of February, 2023
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  -
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "159.6pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  ATMA Exam Date 2023
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  4<sup>th</sup> Week of February, 2023
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  -
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "159.6pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  ATMA 2023 Exam Result Date
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  1<sup>st</sup> Week of March, 2023
                </p>
              </td>
              <td
                style={{
                  width: "159.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={213}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  &nbsp;
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA 2023 Eligibility Criteria</b>
        </p>
        <p className="MsoNormal">
          Eligibility criteria of ATMA exam are similar to other management
          entrance exams. Check out the ATMA eligibility criteria below:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Candidate must be an Indian resident.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ textIndent: "-0.25in" }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Any graduate with a minimum of 50 per cent aggregate or equivalent
          CGPA, from a recognised university, is eligible to apply.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ textIndent: "-0.25in" }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Candidates who are in their final year of graduation can apply
          provisionally.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          There is no upper age limit for applying and appearing in ATMA exam.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA Application Process </b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          After the notification of ATMA February Exam, the application form
          will be available online on the official website{" "}
          <a href="https://www.atmaaims.com">https://www.atmaaims.com</a>. In
          order to avoid any last-minute delays, candidates must stay aware of
          the start and last date of ATMA registration. The ATMA application
          process includes the following steps:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          1.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          Creating a new user profile by filling out the ATMA registration form
          with details such as name, date of birth, gender, email ID and phone
          number, etc.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          2.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          Paying the ATMA application fee via credit/debit card, internet
          banking, UPI or other banking wallets.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          a.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          Candidates need to make payment of Rs. 1,600 for the ATMA 2023
          entrance exam. The dates of fee payment will be notified.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          3.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          Filling out the complete ATMA application form using PID number and
          password.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          a.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          After making payments, use the login credentials and enter PID created
          along with the password to log in. Now start filling out the ATMA
          application form.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          b.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          Fill in details such as name, email id, gender, address, nationality,
          educational background, qualification details, and a professional
          degree (if any). While filling out the application form, choose the
          test centre of your choice.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          4.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          Uploading the required documents in the prescribed format.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "1in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          a.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          Candidates need to upload documents such as a photograph, signature,
          OBC(NC)/SC/ST/DAP certificate, mark sheets, and other documents for
          the purpose of verification.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          5.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          Once all the above steps are done, download the confirmation page and
          keep safe its printout for reference.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA 2023 Exam Centers</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          ATMA 2023 is expected to be conducted in about 55 cities. Listed below
          are the tentative names of cities and states for ATMA exam:
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>State</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Name of the City</u>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Andhra Pradesh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kurnool
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Tirupathi
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Vijayawada
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Visakhapatnam
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Assam
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Guwahati
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bihar
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Patna
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Chandigarh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Chandigarh
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Chattisgarh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Raipur
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Goa
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Panaji
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Gujarat
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ahmedabad / Gandhinagar
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Surat
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Vadodara
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Haryana
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Faridabad
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Gurugram
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jharkhand
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Ranchi
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Karnataka
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bengaluru
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Hubli-Dharwad
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mangaluru(Mangalore)
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mysuru(Mysore)
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kerala
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kochi
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Thiruvananathapuram / Trivandrum
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Madhya Pradesh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bhopal
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Gwalior
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Indore
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jabalpur
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kolhapur
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Maharashtra
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Amravati
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Aurangabad
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Beed
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Mumbai
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Navi Mumbai
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Nagpur
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Nashik
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Palghar
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Pune
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Solapur
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Thane
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  New Delhi
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  New Delhi
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Odisha
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Bhubaneswar
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Punjab
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Amritsar
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Rajasthan
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jaipur
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Jodhpur
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Udaipur
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Tamil Nadu
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Chennai
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Coimbatore
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Madurai
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Telangana
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Hyderabad
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Nizambad
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Warangal
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Uttar Pradesh
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Greater Noida – Noida
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kanpur
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Lucknow
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Uttarakhand
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Dehradun
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "140pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={187}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  West Bengal
                </p>
              </td>
              <td
                style={{
                  width: "338.8pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={452}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Kolkata
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA Exam Pattern</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          ATMA question paper will be divided into six sections, where each
          section will carry 30 questions with a total of 180 marks. The
          candidates will have 3 hours to solve the entire question paper.
        </p>
        <table
          className="MsoTableGrid"
          style={{
            width: "428.45pt",
            borderCollapse: "collapse",
            border: "none",
          }}
          width={571}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "176.95pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={236}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>ATMA Exam Sections</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "69.2pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={92}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Number of </u>
                  </b>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Questions</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "97.6pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Maximum Marks </u>
                  </b>
                </p>
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Section-wise</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "84.7pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Time Allocated</u>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "176.95pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={236}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Analytical Reasoning Skills (Paper-I)
                </p>
              </td>
              <td
                style={{
                  width: "69.2pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={92}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "97.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "84.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30 minutes
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "176.95pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={236}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Verbal Skills (Paper-I)
                </p>
              </td>
              <td
                style={{
                  width: "69.2pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={92}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "97.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "84.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30 minutes
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "176.95pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={236}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Quantitative Skills (Paper-I)
                </p>
              </td>
              <td
                style={{
                  width: "69.2pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={92}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "97.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "84.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30 minutes
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "176.95pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={236}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Analytical Reasoning Skills (Paper-II)
                </p>
              </td>
              <td
                style={{
                  width: "69.2pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={92}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "97.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "84.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30 minutes
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "176.95pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={236}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Verbal Skills (Paper-II)
                </p>
              </td>
              <td
                style={{
                  width: "69.2pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={92}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "97.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "84.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30 minutes
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "176.95pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={236}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  Quantitative Skills (Paper-II)
                </p>
              </td>
              <td
                style={{
                  width: "69.2pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={92}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "97.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30
                </p>
              </td>
              <td
                style={{
                  width: "84.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  30 minutes
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "176.95pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={236}
                valign="top"
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "right",
                    lineHeight: "normal",
                  }}
                  align="right"
                >
                  Total
                </p>
              </td>
              <td
                style={{
                  width: "69.2pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={92}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  180
                </p>
              </td>
              <td
                style={{
                  width: "97.6pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={130}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  180
                </p>
              </td>
              <td
                style={{
                  width: "84.7pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={113}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "justify",
                    textJustify: "inter-ideograph",
                    lineHeight: "normal",
                  }}
                >
                  180 minutes
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>Note</b>: 0.25 mark will be deducted from the total score for each
          incorrect response.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <table style={{ border: "1px solid black", width:"63.5%" }} id="display-data">
  <thead>
    <tr>
      <th colSpan={2} style={{ border: "1px solid black" }}>
        MBA Entrance Exam &amp; MBA/PGDM Admissions – Apply Now
      </th>
    </tr>
  </thead>
  <tbody id="display-data-body">
    {data.map((item) => {
      return (
        <tr>
          <td>
            <a href={item.url} target="_blank">
              {item.display_name}
            </a>
          </td>
          <td>
          <a href={item.url} target="_blank">
              {item.display_name}
            </a>

          </td>
        </tr>
      );
    })}
  </tbody>
</table>

        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA Exam Syllabus</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          The three major sections in the ATMA syllabus are Quantitative Skills,
          Verbal Skills, and Analytical Reasoning Skills. Each section is
          further divided into two parts. Each of the six sections will have 30
          questions in MCQ format. Various topics covered in these sections are:
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <table
          className="MsoTableGrid"
          style={{ borderCollapse: "collapse", border: "none" }}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "211.85pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={282}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Syllabus</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "266.95pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={356}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Important Topics</u>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "211.85pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={282}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Quantitative Skills
                </p>
              </td>
              <td
                style={{
                  width: "266.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={356}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Percentage, Time and Work, Pie Chart and Bar Graph, Profit and
                  Loss, Number System, Mensuration, Time-Speed-Distance, Data
                  Interpretation, Basic Arithmetic, Simple and Compound
                  Interest, Algebra, Probability, Average, Geometry,
                  Progression, Inequalities, Allegation, Ratio and Proportion
                  Variations, Set Theory, Quadratic Equation, Functions,
                  Logarithm, Series.
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "211.85pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={282}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Verbal Skills
                </p>
              </td>
              <td
                style={{
                  width: "266.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={356}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Reading Comprehension, Synonyms &amp; Antonyms, Grammar,
                  Jumbled Sentences, Word Meaning, Sentence Completion.
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "211.85pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={282}
              >
                <p
                  className="MsoNormal bold1"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Analytical Reasoning Skills
                </p>
              </td>
              <td
                style={{
                  width: "266.95pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={356}
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  Strike the odd one out, Blood Relations, Analogy, An
                  arrangement of letters, Visual Reasoning, Syllogism,
                  Coding-Decoding, Verbal Logic, Data Sufficiency, Statement–
                  Conclusion, Number Series
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA Preparation Tips</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Candidates planning to appear for February exam of ATMA 2023 must
          start there preparation six to nine months before the entrance exam.
          They must devise an exam preparation plan which should include section
          wise preparation schedule:
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <u>Quantitative Ability and Data Analysis:</u>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Revise all the sections of mathematics by solving questions from class
          10 and class 12 math textbooks.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Learn all the common formulae and learn tricks to solve questions
          quickly.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Solve 20 – 25 questions daily of all types to increase speed, accuracy
          and consistency.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <u>Verbal Ability or Language Comprehension:</u>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Improve your vocabulary.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Regularly practice questions of sentence jumble, paragraph jumble.
          Practice reading paragraphs and solving questions on them. Regularly
          read magazines and newspapers to learn word meanings, idioms, and
          phrases.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Refer to the grammar books to improve your knowledge of basic grammar.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <u>Analytical Reasoning:</u>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          This section of ATMA is considered to be one of the most difficult
          ones. To do well in this section, it is recommended that you regularly
          practice solving puzzles and riddles.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Practice questions on coding and decoding to improve your critical
          thinking. Practice reading statements and try to arrive at logical
          conclusions and identify fallacious logic.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Practice as much of previous years’ question papers to get an idea
          about the question pattern.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA 2023 Admit Card</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          ATMA 2023 Admit Card will be released on the official website of ATMA
          for candidates to download. Candidates who have registered
          successfully will be able to download the admit card. Given below is
          the process to download ATMA admit card 2023 from the ATMA AIMS
          website:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Visit the official website of ATMA AIMS
          <a href="https://www.atmaaims.com">https://www.atmaaims.com</a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Login using PID number and password
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Click on the 'Print E-admit card' link
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Read the instructions, click on the check box and take a printout of
          the ATMA admit card 2023
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA 2023 Exam Day Guidelines</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Candidates appearing for the ATMA exam must keep in mind the following
          points for the exam day:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          ATMA exam timing is 2:00 PM to 5:00 PM, so the candidates need to
          reach the exam venue by 1:00 PM to complete identification and other
          processes.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          The candidates need to carry two printouts of the ATMA admit card with
          passport size photograph affixed to it and photo ID proof (original
          and photocopy) issued by the Government of India.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Candidates also need to carry a printout of the ATMA application form.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Candidates should affix the photograph which matches the application
          form.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Gadgets such as digital watches, statistical tables, calculators, etc.
          are not permitted in the exam centre.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA 2023 Result</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          For the February 2023 session of ATMA exam, result would be announced
          in the 1<sup>st</sup> Week of March, 2023. ATMA exam result is
          declared on the official website in the form of a scorecard that
          contains overall as well as sectional scores. Aspirants are required
          to download their ATMA results from the official website by following
          the below steps:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Go to the official website of AIMS ATMA
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Select exam date
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Enter the Registration Number/Roll Number/PID and enter the password
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          ATMA results will be displayed on the screen
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Download the result
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA 2023 Counseling</b>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          AIMS will release counselling date on its official website after the
          result is announced. The Counselling process enables the candidates to
          understand their options for opting for the college or university
          accordingly.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Candidates will be allotted seats in one of the five institutions
          through ATMA 2022 counselling, which they had selected during
          registration. Seat allotment will depend on the rank and college
          cutoffs.
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          After the results are declared, each participating institute may have
          further procedures to be conducted before the final selection of
          candidates.
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          These processes will involve Group Discussion (GD), Written Ability
          Test (WAT), and Personal Interview (PI) rounds. It is only after these
          processes that institutes will finally declare the merit list of
          selected candidates.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          <b>ATMA 2023 Cut Off Marks</b>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Tentative cut off of ATMA 2023 for various top colleges has been
          provided in the table below. This will give an idea about the expected
          cut off these colleges:
        </p>
        <table
          className="MsoTableGrid"
          style={{
            width: "6.65in",
            borderCollapse: "collapse",
            border: "none",
          }}
          width={638}
          cellSpacing={0}
          cellPadding={0}
          border={1}
        >
          <tbody>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>Colleges Accepting ATMA Score</u>
                  </b>
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  border: "solid black 1pt",
                  borderLeft: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  <b>
                    <u>ATMA Cut Off (Percentile)</u>
                  </b>
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Prin L.N. Welingkar Institute of Management Development and
                  Research (WeSchool), Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  90
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Jaipuria Institute of Management, Lucknow
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  ISBM Pune: International School of Business &amp; Media
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Pune Institute of Business Management, Pune
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IPE Hyderabad: Institute of Public Enterprise
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IMS Ghaziabad: Institute of Management Studies
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  ITM Business School, Navi Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Jaipuria Institute of Management, Noida
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Vignana Jyothi Institute of Management – VJIM Hyderabad
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  75
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  PSGIM Coimbatore: PSG Institute of Management
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  75
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IBA Bangalore: Indus Business Academy
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  85
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  Universal Business School, Navi Mumbai
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  IES MCRC Mumbai: IES Management College And Research Centre
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  75
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  SCMS Cochin: SCMS Cochin School of Business
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  70
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  NDIM New Delhi: New Delhi Institute of Management
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  80
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  FIIB New Delhi
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  70
                </p>
              </td>
            </tr>
            <tr>
              <td
                style={{
                  width: "343.75pt",
                  border: "solid black 1pt",
                  borderTop: "none",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={458}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{ marginBottom: "0in", lineHeight: "normal" }}
                >
                  VESIM Mumbai: Vivekanand Education Society Institute of
                  Management Studies Research
                </p>
              </td>
              <td
                style={{
                  width: "135.05pt",
                  borderTop: "none",
                  borderLeft: "none",
                  borderBottom: "solid black 1pt",
                  borderRight: "solid black 1pt",
                  padding: "0in 5.4pt 0in 5.4pt",
                }}
                width={180}
                valign="top"
              >
                <p
                  className="MsoNormal"
                  style={{
                    marginBottom: "0in",
                    textAlign: "center",
                    lineHeight: "normal",
                  }}
                  align="center"
                >
                  75
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>MBA / PGDM Colleges Accepting ATMA Score: City-Wise</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Admission criteria for colleges accepting ATMA score would vary
          depending upon the B-School. Each institute conducts the admission
          independently. Candidates must fill the admission forms of their
          preferred institutes to be eligible for selection process. MBA / PGDM
          admission through ATMA includes three main processes:
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Shortlisting of candidates based on ATMA score for counseling
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Group Discussion and Personal Interview rounds
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          <span style={{ fontFamily: "Symbol" }}>
            ·
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            </span>
          </span>
          Final selection for MBA / PGDM admission
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Candidates can check top MBA / PGDM colleges in their preferred city
          for admission through ATMA from the tables below.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p className="MsoNormal">
          Candidates can check top MBA / PGDM colleges across India for
          admission through MAT by visiting the following link:
        </p>
        <p>
          <a href>Top MBA / PGDM Colleges in Delhi-NCR Accepting CMAT</a>
        </p>
        <p>
          <a href>Top MBA / PGDM Colleges in Mumbai Accepting CMAT</a>
        </p>
        <p>
          <a href>Top MBA Colleges in Bangalore Accepting CMAT</a>
        </p>
        <p>
          <a href>Top MBA Colleges in Bangalore Accepting CMAT </a>
        </p>
        <p>
          <a href>Top MBA Colleges in Hyderabad Accepting CMAT</a>
        </p>
        <p>
          <a href>Top MBA Colleges in Kolkata Accepting CMAT </a>
        </p>
        <p>
          <a href>
            To check more top MBA / PGDM colleges across India, click here
          </a>
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>FAQs – ATMA Exam 2023</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          <b>&nbsp;</b>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What is ATMA?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: ATMA (AIMS Test for Management Admissions) is a national-level
          entrance exam conducted by AIMS (Association of Indian Management
          School). It is a single-window test held for admission to various
          post-graduate programs like MBA, PGDM, MMS, MCA etc.
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. When is ATMA 2023 February exam?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: ATMA 2023 February exam is expected to be held in the 4
          <sup>th</sup> week of February, 2023. Final dates would e announced
          once the notification comes out on the official website of ATMA.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: When is the last date to apply for ATMA 2023?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: The ATMA registration 2023 schedule is not yet announced.
          However, it is expected the that registration will start from 2
          <sup>nd</sup> week of December, 2022 and will end in 3<sup>rd</sup>{" "}
          Week of February, 2022.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q: Can I appear for ATMA exam more than once?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: Yes, ATMA is held four to five times a year. Candidates can
          appear more than once and the score of the last exam attempted would
          be considered for admissions.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q. What is the application fee for ATMA?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: The application fee for ATMA is INR 1,600 including GST. There is
          a 25% fee concession for candidates belonging to NE states and women
          candidates.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q: What are all the sections available in the ATMA syllabus?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: There are three sections available in the ATMA exam syllabus,
          namely:
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          1.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          Analytical Reasoning Skills
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          2.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          Verbal Skills
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            textAlign: "justify",
            textJustify: "inter-ideograph",
            textIndent: "-0.25in",
          }}
        >
          3.
          <span style={{ font: '7pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
          </span>
          Quantitative Skills
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q: What is PID number?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: The PID number or Personal Identification Number is a unique
          number assigned to the candidate at the time of filling the ATMA
          application form which can be used by him/her to log in to their ATMA
          account.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q. What is the eligibility criterion for the ATMA 2023 exam?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: Any graduate candidate with a 50 per cent aggregate (45 per cent
          for reserved category) can apply for the exam. The candidates must
          also be a citizen of India.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q: Is there any age restriction for the candidates who appear for the
          ATMA exam?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: No, there is no age restriction for the candidates who apply for
          the ATMA exam, however, they must fulfil the other eligibility
          criteria before appearing for the ATMA exam.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q: Can I pay the ATMA registration fee by cash/DD or net banking?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: ATMA registration fee can be paid only through debit/credit
          cards. There is no provision of paying the application fee offline.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q. I am unable to upload my signatures in the registration form. What
          should I do?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: There is a set limit to upload a photograph and digital
          signatures in the ATMA registration form. The size of the file should
          be between 20 KB to 50 KB. The dimensions of the signatures should be
          140 X 60 Pixels.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q. How many test cities can I choose while filling out the ATMA
          application form?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: Candidates can choose up to two test cities according to their
          preferences at the time of filling the application form.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q. Is there any reservation in ATMA exam result?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: No, the ATMA application form is the same for all the candidates.
          They have to choose the category they belong to and provide the
          supporting documents at the time of admission to the respective
          college. Reservation is totally college dependent.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q. Which documents are needed for uploading while filling the ATMA
          registration form?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: Candidates need to upload their passport size photograph,
          digitally scanned signatures, caste certificate (if applicable),
          copies of Class 10, Class 12 and graduation mark sheets. Sponsored
          applicants are required to upload sponsoring certificates as per the
          format available on the ATMA website.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q. Can I make corrections in the ATMA application form after
          submission?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: No changes can be made once the ATMA application form has been
          submitted. Candidates must fill the application form carefully and
          check the details before submitting the form.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q. Is there any negative marking in ATMA?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: Candidates will be awarded one mark for every correct answer and
          0.25 marks are deducted for every incorrect answer.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Q. Can I change my test centre after the admit card has been issued?
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          Ans: No, candidates cannot change their test centres once the admit
          card has been issued. The test centre can only be changed if the
          request has been made at least 10 days prior to the exam and is
          subject to the availability of seats and approval of test
          administrator.
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{
            marginBottom: "0in",
            textAlign: "justify",
            textJustify: "inter-ideograph",
          }}
        >
          &nbsp;
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Q. How many colleges accept ATMA score for admission?
        </p>
        <p
          className="MsoNormal"
          style={{ textAlign: "justify", textJustify: "inter-ideograph" }}
        >
          Ans: Over 700+ MBA / PGDM colleges across India accept ATMA score for
          admission. These includes some of the top colleges like Prin L.N.
          Welingkar Institute of Management Development and Research (WeSchool)
          - Mumbai, Jaipuria Institute of Management - Lucknow, ISBM Pune:
          International School of Business &amp; Media, Pune Institute of
          Business Management - Pune, IPE Hyderabad: Institute of Public
          Enterprise etc.
        </p>
      </div>
    </div>
  );
};
export default ATMA;
