import AdmissionProcess from "../Components/public/static/PATH 1/Admission Process";
import DistanceMba from "../Components/public/static/PATH 1/Distance MBA";
import ExecutiveMBA from "../Components/public/static/PATH 1/Executive MBA";
import IntergratedMba from "../Components/public/static/PATH 1/Integrated MBA";
import MbaorPgdm from "../Components/public/static/PATH 1/MbaorPgdm";
import OnlineMBA from "../Components/public/static/PATH 1/Online MBA";
import PartTimeMbaa from "../Components/public/static/PATH 1/PART Time Mbaa ";
import WhyMba from "../Components/public/static/PATH 1/WhyMba";
import BankingFinance from "../Components/public/static/PATH 2/Banking _Finance";
import BusinessAnalytics from "../Components/public/static/PATH 2/Business Analytics";
import FinanceManagemant from "../Components/public/static/PATH 2/Finance Management";
import HealthcareManagement from "../Components/public/static/PATH 2/Healthcare Management";
import HRManagament from "../Components/public/static/PATH 2/HR Management";
import InternationalBusiness from "../Components/public/static/PATH 2/International Business";
import MarketingManagement from "../Components/public/static/PATH 2/Marketing Management";
import MBASpecialisation from "../Components/public/static/PATH 2/MBA Specialisation";
import RuralManagements from "../Components/public/static/PATH 2/Rural Management";
import ATMA from "../Components/public/static/PATH 3/ATMA";
import CAT2022 from "../Components/public/static/PATH 3/CAT 2022";
import CMAT2023Incomplete from "../Components/public/static/PATH 3/CMAT 2023 - Incomplete";
import IBSAT2023 from "../Components/public/static/PATH 3/IBSAT 2023";
import MAHCET from "../Components/public/static/PATH 3/MAH CET";
import MATIncomplete from "../Components/public/static/PATH 3/MAT - Incomplete";
import NMAT2022 from "../Components/public/static/PATH 3/NMAT 2022";
import Snap2022 from "../Components/public/static/PATH 3/SNAP 2022";
import XAT2023 from "../Components/public/static/PATH 3/XAT 2023";

import Signup from "../Pages/signup";
import LoginWrapper from "../Pages/Auth";
// import Signup from "../Pages/Auth/signup";
import Collages from "../Pages/Collages";
import CollegeDetails from "../Pages/CollegeDetails";
import CollegeList from "../Pages/CollegeList";
import CustomPage from "../Pages/CustomPages";
import Home from "../Pages/Home";
import Login from "../Pages/login";
import Profile from "../Pages/Profile";

export const MainRoutes = [
  {
    path: "/",
    component: Home,
    children: null,
    guard: null,
  },
  {
    path: "/test",
    component: Home,
    children: null,
    guard: null,
  },
  {
    path: "/cms",
    component: CustomPage,
    children: null,
    guard: null,
  },
  {
    path: "/collages",
    component: Collages,
    children: null,
    guard: null,
  },
  {
    path: "/login",
    component: Login,
    children: null,
    guard: null,
  },
  {
    path: "/sign-up",
    component: Signup,
    children: null,
    guard: null,
  },
  {
    path: "/college-list",
    component: CollegeList,
    children: null,
    guard: null,
  },
  {
    path: "/college-details",
    component: CollegeDetails,
    children: null,
    guard: null,
  },
]
export const DefaultRoutes = [
  
  {
    path: "/admission-Process",
    component: AdmissionProcess,
    children: null,
    guard: null,
  },
  
  {
    path: "/distance-mba",
    component: DistanceMba,
    children: null,
    guard: null,
  },
  
  {
    path: "/executive-mba",
    component: ExecutiveMBA,
    children: null,
    guard: null,
  },
  
  {
    path: "/integrated-mba",
    component: IntergratedMba,
    children: null,
    guard: null,
  },
  {
    path: "/mba-or-pgdm",
    component: MbaorPgdm,
    children: null,
    guard: null,
  },
  {
    path: "/why-mba",
    component: WhyMba,
    children: null,
    guard: null,
  },
  
  {
    path: "/admission-process",
    component: AdmissionProcess,
    children: null,
    guard: null,
  },
  
  {
    path: "/mba-more-specialisations",
    component: MBASpecialisation,
    children: null,
    guard: null,
  },
  
  {
    path: "/mba-in-marketing",
    component: MarketingManagement,
    children: null,
    guard: null,
  },
  {
    path: "/mba-in-finance",
    component: FinanceManagemant,
    children: null,
    guard: null,
  },
  {
    path: "/mba-in-hr",
    component:HRManagament,
    children: null,
    guard: null,
  },
  {
    path: "/mba-in-ib",
    component: InternationalBusiness,
    children: null,
    guard: null,
  },
  {
    path: "/mba-in-ba",
    component: BusinessAnalytics,
    children: null,
    guard: null,
  },
  
  {
    path: "/online-mba",
    component: OnlineMBA,
    children: null,
    guard: null,
  },
  
  {
    path: "/part-time-mba",
    component: PartTimeMbaa,
    children: null,
    guard: null,
  },
  
  
  
  {
    path: "/mba-in-rm",
    component: RuralManagements,
    children: null,
    guard: null,
  },
  
  {
    path: "/mba-in-hc",
    component: HealthcareManagement,
    children: null,
    guard: null,
  },
  
  
  {
    path: "/cat-2022",
    component: CAT2022,
    children: null,
    guard: null,
  },

  
  {
    path: "/xat-2023",
    component: XAT2023,
    children: null,
    guard: null,
  },

  {
    path: "/mat",
    component: MATIncomplete,
    children: null,
    guard: null,
  },

  {
    path: "/snap-2022",
    component: Snap2022,
    children: null,
    guard: null,
  },

  {
    path: "/atma",
    component: ATMA,
    children: null,
    guard: null,
  },
  {
    path: "//nmat-2022",
    component: NMAT2022,
    children: null,
    guard: null,
  },
  {
    path: "/cmat-2023",
    component: CMAT2023Incomplete,
    children: null,
    guard: null,
  },
  {
    path: "/ibsat",
    component: IBSAT2023,
    children: null,
    guard: null,
  },
  {
    path: "/ibsat",
    component: IBSAT2023,
    children: null,
    guard: null,
  },
  


  
  

];

export const AuthRoutes = [
  {
    path: "/sign-up",
    component: Signup,
    children: null,
    guard: null,
  },
  {
    path: "/login",
    component: Login,
    children: null,
    guard: null,
  },
];


export const ProtectedRoutes = [
  {
    path: "/profile",
    component: Profile,
    children: null,
    guard: null,
  },
  
  
]